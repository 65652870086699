.active-onBoard {
  border-color: #7ec4f6;
  background: #e5f5ff;
}
.dashboard {
  padding: 35px;
  &__title {
    margin: 0px;
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 32px;
  }
  &__head {
    display: flex;
    gap: 15px;
    .MainLabel {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      color: #000;
      span {
        font-weight: 700;
      }
    }
    p {
      color: #aaa;
      font-size: 14px;
      line-height: 32px;
    }
  }
}
.stepperStage {
  border: 1px solid #aaa;
  margin: 10px 0;
  box-shadow: 0 2px 8px #00000026;
  border-radius: 4px;

  ul {
    margin: 0;
    padding: 25px 55px;
    gap: 8px;
    display: flex;
    align-items: center;
    list-style-type: none;
    flex-wrap: wrap;

    li {
      background: #fff;
      border: 1px solid #d2d6dc;
      font-size: 14px !important;
      color: #8d8c8c45 !important;
      text-transform: capitalize;
      min-width: 28px;
      height: 52px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      z-index: 1;
      text-align: left;
      text-decoration: none;
      box-sizing: border-box;

      margin-right: 0px;
      padding: 25px;
      &:first-of-type {
        z-index: 13;
      }

      &:nth-child(2) {
        z-index: 12;
      }

      &:nth-child(3) {
        z-index: 11;
      }

      &:nth-child(4) {
        z-index: 10;
      }

      &:nth-child(5) {
        z-index: 9;
      }

      &:nth-child(6) {
        z-index: 8;
      }

      &:nth-child(7) {
        z-index: 7;
      }

      &:nth-child(8) {
        z-index: 6;
      }

      &:nth-child(9) {
        z-index: 5;
      }

      &:nth-child(10) {
        z-index: 4;
      }

      &:nth-child(11) {
        z-index: 3;
      }

      &:nth-child(12) {
        z-index: 2;
      }

      &:nth-child(13) {
        z-index: 1;
      }
    }
  }

  li:last-child {
    .stepperStage_item {
      &-link {
        &::before,
        &::after {
          display: none;
        }
      }
      &.active-onBoard {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(255, 77, 79, 0.2);
      }
    }
  }

  &_item {
    &.active-onBoard {
      border-color: #7ec4f6;
      background: #e5f5ff;
      .ui-breadcrumb__item-link::after {
        background: #e5f5ff;
        border-color: #7ec4f6;
      }
    }
  }
}

.stepperStage_item.active-onBoard .stepperStage_item-link::after {
  background: #e5f5ff;
  border-color: #7ec4f6;
}
.stepperStage_item-link::after {
  content: "";
  background: #fff;
  border-right: 1px solid #d2d6dc;
  border-top: 1px solid #d2d6dc;
  height: 40px;
  width: 40px;
  display: block;
  transform: rotate(45deg);
  position: absolute;
  right: -18px;
  border-radius: 8px;
  top: 4px;
}

.stepperStage_item-link {
  color: #ed8154;
  text-decoration: none;
  margin-right: 12px;
}
.stepperStage_item-link::before {
  content: "";
  background: #fff;
  height: 40px;
  width: 40px;
  display: block;
  transform: rotate(45deg);
  position: absolute;
  right: -26px;
  z-index: 0;
  border-right: 1px solid #d2d6dc;
  border-top: 1px solid #d2d6dc;
  border-radius: 8px;
  top: 4px;
}

.Files {
  margin-top: 55px;
  &_inner {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 22px;
  }
}
.sectionLabel {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  span {
    font-weight: 700;
    margin-left: 4px;
  }
}
.statusAction {
  background-color: #cf3e4a;
  padding: 0 10px;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  margin-right: 25px;
  font-weight: 400;
  color: #fff;
}
.FilesUpload {
  max-width: 690px;
  border: 1px solid #aaa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1490196078);
  padding: 30px 15px;
  border-radius: 4px;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 12px 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1490196078);
    border-radius: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    color: #000000d9;
    font-weight: 500;
  }
  button {
    padding: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }
  .pending {
    font-size: 12px;
    font-weight: 400;
    background-color: #ffc701;
    color: #fff;
    padding: 6px 10px;
    line-height: normal;
    border-radius: 2px;
  }
}
.intakeForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 30px;
  &_inner {
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #8c8c8c;
    padding-bottom: 0;
    &_Box {
      margin: 30px;
      margin-bottom: 0;
    }
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #ffffff;
    border-radius: 4px;
    border-top: 0.5px solid #8d8c8c45;
    .greenBg {
      background-color: green;
      color: #fff;
    }
    .yellowBg {
      background-color: #fed239;
      color: black;
    }
    .blueBg {
      background-color: #096dd9;
      color: #fff;
    }
    button {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      // color: #434343;
      text-transform: capitalize;
      // &.pending {
      //   background-color: #096dd9;
      //   padding: 5px 16px;
      // }
      // &.complete {
      //   background-color: #02c34f;
      //   padding: 5px 10px;
      //   font-weight: 400;
      // }
    }
  }
  .content {
    background-color: #fff;
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #000000d9;
    font-weight: 500;
    min-height: 80px;
    box-shadow: 0 2px 8px #00000026;
  }
}

@media only screen and (max-width: 1199px) {
  .intakeForm {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 991px) {
  .stepperStage {
    ul {
      margin: 0;
      padding: 25px 35px 25px 25px;
    }
  }
  .dashboard {
    padding: 25px;
    padding-top: 60px;
  }
  .intakeForm {
    gap: 20px;
    &_inner {
      &_Box {
        margin: 15px;
        margin-bottom: 0;
      }
    }
    &_bottom {
      padding: 10px;
    }
    .content {
      padding: 15px;
      font-size: 14px;
      line-height: normal;
    }
  }
}
@media only screen and (max-width: 767px) {
  .intakeForm {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 575px) {
  .dashboard {
    padding: 15px;
    padding-top: 60px;
  }
  .FilesUpload {
    &__inner {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
@media only screen and (max-width: 414px) {
  .intakeForm {
    grid-template-columns: 1fr;
  }
}
