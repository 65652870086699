.create-new-board {
  width: 100%;
  .tab-section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .innerTabs {
      width: auto;
      height: auto;
      overflow: hidden;
    }
  }
  .create-new-board-inner {
    padding-bottom: 30px;
  }
  .create-new-board-footer {
    display: flex;
    justify-content: right;
    padding: 15px;
    button {
      line-height: normal;
      padding: 7px 15px;
      font-size: 13px;
      color: #ffffff;
      background-color: #096dd9;
    }
  }
  .innerTabs {
    gap: 25px;
    &__item button {
      font-size: 18px;
      padding: 0;
      cursor: pointer;
    }
  }
}
.border-none {
  input,
  fieldset,
  input:focus {
    border: 0px !important;
    outline: none;
    background: none !important;
  }
}
.checklist{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  width: 100%;
  background: #ECF6FF;
  padding: 0px 20px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: grab;
}

.checklist-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: 80px;
    background-color: rgb(9, 109, 217);
    color: white;
    border: 0px;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
  }
}
.automationTabbing {
  margin-bottom: 30px;
  &__inner {
    list-style-type: none;
    margin: 0;
    padding: 15px;
    width: 275px;
    height: 350px;
    border: 1px solid #ccc;
    border-radius: 6px;
    ul {
      padding: 0;
      margin: 0;
    }

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .btn {
        border: none;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        transition: 0.2s;
        svg {
          height: 16px;
          width: 16px;
        }
        &.btnDelt {
          svg {
            fill: rgb(9, 109, 217);
          }
        }
      }
      .current {
        display: flex;
        border-bottom: 1px solid transparent;
        &:hover {
          border-bottom: 1px solid #096dd9;
          color: #096dd9;
        }
      }
      .btnTab {
        display: flex;
        border-bottom: 1px solid transparent;

        &__current {
          border-bottom: 1px solid #096dd9;
          color: #096dd9;
        }

        &:hover {
          border-bottom: 1px solid #096dd9;
          color: #096dd9;
        }
      }
    }
  }
  .addBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  &__detail {
    width: 100%;
    h4 {
      margin: 0;
      font-size: 18px;
    }
  }
}
.customToggler {
  margin: 0;
  gap: 10px;
}

.boardLargeModel .MuiDialog-paper {
  min-width: 750px;
  width: 100%;

  @media only screen and (max-width: 991px) {
    min-width: 0px;
  }
}

.ml-0 {
  margin-left: 0 !important;
}

.dropdowncard {
  padding: 6px 10px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 10px;
  .darkLabel {
    font-weight: 700;
    font-size: 14px;
    min-width: 50px;
  }
}

.DashboardHead {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 10px;
}

@media only screen and (max-width: 991px) {
  .DashboardHead {
    margin: 0;
    margin-top: 42px;
  }
}
.settingPage {
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
}

.settingBox {
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 25px;
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: revert;
    margin: 18px 0;

    &-center {
      justify-content: center;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
      font-family: "Roboto", sans-serif;
    }

    .MuiFormControlLabel-root {
      margin: 0 !important;
      gap: 5px;
      min-height: 35px;
    }

    input[type="number"] {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      min-height: 35px;
      width: 70px;
      padding: 5px 10px;
    }

    .fromGroup-chips {
      margin-top: 10px;
    }
  }

  &__part-inner {
    padding-left: 28px;
  }

  &__formBox {
    max-width: 415px;
    width: 100%;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0 0 15px;
    }

    .fromGroup:not(:last-child) {
      margin-bottom: 30px;
    }

    .fromGroup input,
    .fromGroup .MuiInputBase-root {
      background: #fafafa;
    }

    fieldset {
      border: 1.2px solid #096dd9 !important;
    }
  }
}

.MuiSwitch-root {
  padding: 0;
  height: 20px;
  width: 40px;
  border-radius: 50px;

  .MuiButtonBase-root.MuiSwitch-switchBase {
    padding: 2px;
    background: transparent !important;
  }

  .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
  }

  .MuiSwitch-track {
    border-radius: 50px;
    background-color: #000000;
    opacity: 0.38;
  }

  .Mui-checked + .MuiSwitch-track {
    opacity: 100% !important;
    border-radius: 50px;
    opacity: 100% !important;
  }
}

.formLogo {
  height: 34px;
}
.survetFormLogo {
  height: 15px;
}
.jotformLogo {
  height: 40px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .catelogHeader {
    display: block !important;
    & > div:last-child {
      width: 100% !important;
      margin-top: 22px !important;
    }
  }
}
.settingBox__field-part {
  display: grid !important;
  grid-template-columns: 1fr 2fr !important;
}
.customSelectDrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .customSelect {
    max-width: 300px;
    width: 100%;
  }
  & > .MuiInputBase-root {
    height: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .settingBox {
    &__field {
      align-items: flex-start;
      flex-direction: column;
      display: flex !important;
    }

    &__part-inner {
      padding-left: 18px;
    }

    &__field-title {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

/*Message Template Block Start*/
.message-templateBclobk {
  margin: 0;

  .fromGroup-chips {
    align-items: center;
    label {
      color: #000;
      margin-bottom: 0;
    }
    .MuiPaper-rounded {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  textarea {
    -webkit-appearance: value;
    -moz-appearance: value;
    appearance: value;
    resize: none;
  }
  .button {
    font-weight: 400;
  }
}
.stepperForm__footer {
  .button {
    font-weight: 400;
  }
}

.fromGroup {
  margin-bottom: 12px;

  .MuiInput-root {
    width: 100%;

    &::after,
    &::before {
      display: none;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    width: 100%;

    &.Mui-focused {
      input {
        background: #ecf6ff;
        border: 1px solid #096dd9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
      }
    }
  }

  .MuiSelect-select {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: none;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  input {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d9d9d9;
  }

  &-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .MuiPaper-root {
      height: 10px;
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      box-shadow: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      font-family: "Roboto", sans-serif;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      margin: 4px 0;

      // &:not(:last-child) {
      //   margin-right: 15px;
      // }
    }
  }

  .fromGroup-chip-active {
    // background: #ecf6ff;
    color: #096dd9;
    border: 1px solid #096dd9;
  }
}
.pageTemplate-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .addBtn {
    white-space: nowrap;
  }
}

.fromGroup-chip-active {
  border: 1px solid #096dd9 !important;
  color: #096dd9 !important;
}
.border-boxBlock {
  border: 1px solid #d9d9d9;
  background: #fafafa;
  padding: 6px;
  width: 110px;
  // margin: 0 auto;
}

.listingTable-catalog {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 22px;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    // line-height: 32px;
    color: #000000;
    margin: 0;
  }

  .tableResponsive {
    box-shadow: none;
    padding: 5px;
  }

  table {
    border-spacing: 0 8px;

    tr {
      min-height: 48px;
      margin-bottom: 8px;
    }

    th,
    td {
      padding: 0px 16px;
      // text-align: left;
      font-family: "Roboto", sans-serif;
      white-space: pre;
      vertical-align: middle !important;
    }

    .MuiTableCell-head {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre;
    }

    tbody {
      tr {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px !important;

        tr:nth-child(odd) {
          background: #ecf6ff;
        }
      }

      td {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
        vertical-align: middle !important;
      }
    }
  }
}

.border-boxBlockForm {
  border: 1px solid #d9d9d9;
  background: white;
  padding: 3px 11px;
  width: 200px;
  border-radius: 5px;
  font-size: larger;
}
