.sv-action__content .sd-navigation__next-btn {
    position: relative;
  }
  .sv-action__content .sd-navigation__next-btn::after {
    content: "Start Now";
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: red;
  }
  
  .survey-next-button {
    /* Add your custom styles here */
    /* For example, changing the text */
    content: "Custom Next Button Text";
    /* You can also change other styles like color, font-size, etc. */
  }
  
  /* .sd-action-bar.sd-footer{
      display: none;
  } */
  /* .sv-action__content{
      display: none;
  } */
  /* .sd-btn.sd-navigation__next-btn {
    display: none;
    content: "Start Now" !important;
    display: inline;
  } */
  
  .navigation-block {
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    background-color: #fff;
  }
  
  .navigation-page-selector {
    flex-basis: 240px;
  }
  
  .navigation-progress-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .navigation-buttons-container {
    display: flex;
    gap: 8px;
  }
  
  .navigation-button {
    line-height: 24px;
    font-weight: 600;
    font-family: "Open Sans";
    font-size: 16px;
    padding: 12px 32px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    background-color: #096dd9;
    color: #fff;
    cursor: pointer;
    outline: none;
  }
  
  .navigation-button:focus {
    color: #096dd9;
    background-color: #fff;
    box-shadow: 0 0 0 2px #096dd9;
  }
  
  .navigation-button:hover {
    color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    background-color: #096dd9;
  }
  
  .navigation-text-container {
    position: relative;
    width: 100%;
  }
  
  .navigation-text {
    position: absolute;
    right: 0;
    top: 8px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.45);
  }
  .sd-title.sd-container-modern__title {
    color: "#096dd9";
  }
  
  .sd-header__text {
    color: #096dd9;
  }
  
  .topNavBlock {
    position: absolute;
    /* top: 24px; */
    z-index: 11;
    right: 0;
    background: transparent;
    padding: 0 18px;
  }
  .topNavBlock button {
    font-size: 14px;
    padding: 8px 20px;
    position: relative;
    top: 23px;
  }
  .surveyFormBlock {
    position: relative;
  }
  .surveyFormBlock-btn {
    position: absolute;
    bottom: 0;
    max-width: 640px;
    z-index: 11;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .surveyFormBlock-btn button {
    margin: 0 0 25px !important;
  }
  
  .sd-body.sd-body--static {
    min-height: calc(100vh - 400px);
    box-sizing: border-box;
    justify-content: space-between;
  }
  .sd-progress {
    height: 10px;
    background-color: #d9d9d9;
    display: flex;
    align-items: center;
  }
  .sd-progress__bar {
    height: 6px;
    background-color: #096dd9;
  }
  .sd-title.sd-container-modern__title {
    box-shadow: none;
  }
  .sd-header__text .sd-title .sv-string-viewer {
    color: #000000d9;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  .sd-root-modern {
    background: #fff;
  }
  .sd-element--with-frame {
    box-shadow: none;
    padding: 0;
  }
  #sv-nav-prev input {
    border-radius: 4px;
    border: 1px solid #096dd9;
    background: #fff;
    color: #096dd9;
    padding: 7px 25px;
    margin: 0 auto;
  }
  .sd-question__title .sd-element__num {
    font-size: 16px;
    font-weight: 800;
    color: #000;
  }
  #sv-nav-next input {
    border-radius: 4px;
    border: 1px solid #096dd9;
    background: #096dd9;
    color: #fff;
    padding: 7px 25px;
    margin: 0 auto;
  }
  .sd-action-bar.sd-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .sv-nav-next {
    width: 100% !important;
  }
  
  .sd-progress__text {
    /* top: inherit; */
    top: 2px;
    /* font-size: initial; */
    color: "#fffff";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .surveyTest {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  .TestInner {
    text-align: center;
    padding: 50px;
    box-shadow: 0 0 6px #ccc;
    border-radius: 8px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .surveyTest .testTitle {
    font-size: 34px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .TestInner div {
    color: rgb(9, 109, 217);
    cursor: pointer;
  }
  
  .sd-btn--action{
    background-color: #0B8080 !important;
  }