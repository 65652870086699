.stepperForm {
  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #096dd9;
    max-width: 700px;
    margin: 0 auto 24px;
    padding: 0 20px;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
    }

    .button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
      &:hover {
        background: #fff !important;
        color: #096dd9 !important;
      }
    }
  }

  &__inner {
    max-width: 750px;
    margin: 0 56px;
    padding: 0px 10px;
    padding-top: 30px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
    .MuiSelect-select {
      background: #ecf6ff;
    }
  }

  .MuiSelect-select ~ .MuiOutlinedInput-notchedOutline {
    height: 40px;
    top: 0;

    legend {
      display: none;
    }
  }
}

.authListBox {
  background: #ffffff;
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 100%;

  &__item {
    margin-bottom: 10px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    background: transparent;
    padding: 10px;
    border-radius: 5px;
    &.active {
      background: #d9d9d9;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    line-height: 24px;
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.85);
    //line-break: anywhere;
    flex-wrap: wrap;
  }

  &__removeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      height: 22px;
      width: 22px;
      color: #096dd9;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    padding: 4px 10px;
    cursor: pointer;

    &.activeLink {
      color: #096dd9;
      text-decoration: underline;
      font-weight: 700;
    }
  }
}

.time-group-select {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9.5px 14px !important;
}

.time-group {
  // div {
  //   div {
  //     padding: 11px !important;
  //   }
  // }
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 120px 20px 120px 20px 120px 38px 38px;
  align-items: baseline;
  &__space {
    font-family: "Roboto", sans-serif;
    margin: 0 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.85);

    &--time {
      flex: 50px 0 0;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .weeklyTime {
    text-align: left;
    margin: 0 !important;
    font-size: 14px;

    span {
      margin-left: 0;
    }
  }
}

.removeTimeBtn {
  width: 22px;
  cursor: pointer;
  display: flex;
  height: 100%;
  padding-top: 0px;

  path {
    color: #096dd9;
  }
}

.fromGroup {
  margin-bottom: 4px;
}

.max-w-250px {
  margin: 0 auto;
  max-width: 250px;
}

.InsuranceHeader {
  margin-bottom: 20px;

  .stepperForm__title {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__btn {
    text-align: right;

    button {
      text-transform: unset;
      background-color: rgb(9, 109, 217);
      color: white;
    }
  }
}