.mapWrapper {
  .innerTabs {
    padding: 0;
    // margin: 32px 0 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    // overflow-x: auto;
    // height: 40px;

    &__item {
      padding-bottom: 5px;

      button {
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        position: relative;
        background: transparent;
        border: none;
        box-shadow: none;

        &::after {
          content: "";
          width: 100%;
          height: 3px;
          border-radius: 10px;
          background-color: #096dd9;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          opacity: 0;
        }

        &.active {
          color: #096dd9;
          font-weight: 700;

          &::after {
            opacity: 100%;
          }
        }
      }
    }
  }
  .splitter-layout {
    left: 0;
    & > .layout-splitter {
      flex: 0 0 auto;
      width: 4px;
      height: 100%;
      cursor: col-resize;
      background-color: #ccc;
      background: transparent;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  }
}

.mapUserList {
  overflow-y: auto;
  min-height: 68vh;
  margin: 0 -8px;
  padding: 8px;
  max-height: 72vh;
}

// .cardLevelBlock {
//   margin-bottom: 12px;
//   background: #ffffff;
//   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
//   border-radius: 4px;
//   padding: 8px 24px;
// }

.cardLevelBlock {
  // display: flex;
  // align-items: center;
  // gap: 12px;
  // position: relative;
  // padding-top: 5px;
  // border-top: 1px solid rgb(190, 190, 190);
  // padding: 5px 0;
  overflow: hidden;
  // overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      th {
        padding: 5px;
        width: 75px;
        min-width: 75px;
        &:first-child {
          width: 35px;
          min-width: 35px;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
    tbody {
      border-top: 1px solid #096dd9;
      td {
        padding: 10px 5px;
        width: 75px;
        &:first-child {
          width: 35px;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
    .userMapCart__img {
      position: relative;
      overflow: visible;
      .verifiedIcon {
        top: unset;
        left: unset;
        right: 0;
        bottom: 0;
        height: 16px;
      }
    }
    .userMapCart__statusBox {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      .top-level-title {
        white-space: nowrap;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
      }
      .text-center {
        text-align: center;
      }
    }
  }
  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.userMapCart {
  margin-bottom: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: normal;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  // .top-level-title {
  //   font-size: 10px;
  //   position: absolute;
  //   top: 0;
  // }

  &__img {
    display: flex;
    height: 32px;
    width: 32px;
    overflow: hidden;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32px;
    @media (max-width: 768px) {
      gap: 10px;
      height: 22px;
      width: 22px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      @media (max-width: 768px) {
        object-fit: contain;
      }
    }
  }

  &__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    text-align: left;
    justify-content: space-between;
    span {
      font-weight: 500;
    }
  }

  &__contact {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    justify-content: right; //space-between;
    span {
      font-weight: 400;
    }
  }

  &__statusBox {
    display: flex;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: auto;

      &:not(:last-child) {
        padding-right: 10px;
        margin-right: 10px;
        // border-right: 2px solid #3399ff;
      }
      .top-level-title {
        // font-size: 14px;
        white-space: nowrap;
        font-weight: 400;
      }
    }

    &__name {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.verifiedIcon {
  position: absolute;
  left: 16px;
  top: 24px;
}

.border-box {
  border-top: 2px solid #3399ff;
  margin: 15px 0;
  padding-top: 15px;
}

.border-dot {
  border-top: 1px solid grey;
  margin: 5px 0;
  padding-top: 5px;
}

.fromGroup-chip-active {
  margin-bottom: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: normal;
  background: rgba(186, 214, 253, 0.4);
  color: #096dd9;
  border: 1px solid rgba(113, 172, 255, 0.4);
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

.userClientLevel,
.userClientblock {
  flex-direction: row;
  gap: 12px;
}

.mapUserList {
  overflow-y: auto;
  min-height: 68vh;
  margin: 0 -8px;
  padding: 8px;
}

.mapDetailsTemplate {
  .scheduleMap {
    height: 78vh;

    .gmnoprint {
      left: auto !important;
    }
  }
}

.filterGroup-btn {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  .MuiButtonGroup-firstButton {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
}
.selected-filtersButtons {
  text-align: right;
  .border-button {
    background-color: transparent !important;
    color: #096dd9 !important;
    border: 1px solid #096dd9 !important;
  }
}

.top-btn-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  overflow-x: auto;

  div {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
    margin-bottom: 3px;
  }
  .MuiBox-root {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 22px;
    border: 1px solid #fff;
    position: relative;
    cursor: pointer;
    height: 25px;
    p {
      text-wrap: nowrap;
    }

    //TODO: show blue dot when unreadCount > 0 at entity level
    // &::after {
    //   height: 10px;
    //   width: 10px;
    //   border-radius: 20px;
    //   background-color: #096dd9;
    //   content: "";
    //   position: absolute;
    //   left: 7px;
    //   top: 14px;
    // }
  }
  button {
    height: 42px;
    padding: 0px 30px;
    min-width: 90px;
  }
  .translate-btn{
    max-height: 40px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    margin: 0px;
  }
  .active {
    // color: #096dd9;
    border: 1px solid #096dd9;
  }
}

.scroll-bar {
  overflow-x: hidden;
  max-height: 530px;
  padding: 10px;
}

.commentListItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 26px;
  gap: 8px;

  &__user {
    width: 100%;
    display: flex;
    gap: 10px;

    &-img {
      //height: 32px;
      //width: 32px;
      //flex-grow: 0;
      //flex-shrink: 0;
      //flex-basis: 32px;
      //border-radius: 50%;
      // overflow: hidden;
      // border: 1px solid #ddd;

      img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        padding: 2px;
      }
    }

    &-info {
      width: 100%;
    }

    &-heading {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 8px 0;
    }

    &-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    &-time {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    &-comment {
      background: #ecf6ff;
      border-radius: 4px;
      padding: 12px;
      width: 100%;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &.otherUser {
    .commentListItem__user-comment {
      background: #f5f5f5;
    }
  }

  &.generated {
    .commentListItem__user-comment {
      background: #fff;
      padding: 12px 0;
    }
  }

  &.light-blue-bg {
    background-color: #ecf6ff;
  }

  .top-btn-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;

    div {
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 15px;
    }
    .MuiBox-root {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: 8px 24px;
      border: 1px solid #fff;
      position: relative;
      cursor: pointer;

      //TODO: show blue dot when unreadCount > 0 at entity level
      // &::after {
      //   height: 10px;
      //   width: 10px;
      //   border-radius: 20px;
      //   background-color: #096dd9;
      //   content: "";
      //   position: absolute;
      //   left: 7px;
      //   top: 14px;
      // }
    }
    button {
      height: 42px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .active {
      // color: #096dd9;
      border: 1px solid #096dd9;
    }
  }

  .commentForm {
    padding-bottom: 0;
    border-radius: 10 !important;
    // textarea {
    //   height: 46px !important;
    //   overflow: hidden;
    // }
  }

  .compose-block {
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    gap: 10px;
    align-items: flex-start;
    .compose-user-img {
      img {
        width: 30px;
      }
    }
    .commentListItem__user-img {
      position: relative;
      display: flex;
      align-items: center;
      .active {
        height: 10px;
        width: 10px;
        border-radius: 20px;
        background-color: #096dd9;
        margin-right: 10px;
      }
    }
    .compose-user-heading {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 8px 0;
    }
    .compose-user-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }
    .compose-user-time {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }
    .compose-user-comment {
      padding: 0;
      width: 100%;
      whitespace: "pre-line";
      //line-break: anywhere;
      padding-right: 10px;
    }
  }
  .messageUser {
    margin-bottom: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 24px;
    display: flex;
    align-items: normal;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
  }

  /*
  .messageInternal-block {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 10px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    line-break: anywhere;
    .commentListItem__user-img {
      position: relative;
      display: flex;
      align-items: center;
      .active {
        height: 10px;
        width: 10px;
        border-radius: 20px;
        background-color: #096dd9;
        margin-right: 10px;
      }
    }

    .compose-user-name {
      font-weight: normal;
    }
    .compose-user-time {
      margin-left: auto;
      color: #333;
    }
    .messageTicket-number {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 10px;
      span {
        color: #333;
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0 8px;
        box-shadow: 0px 0px 7px 0px #e2e2e2;
      }
    }
    &.light-blue-bg {
      background-color: #ecf6ff;
    }
  }
*/
  .scroll-bar {
    overflow-x: hidden;
    max-height: 530px;
  }

  .userMapCart__contact {
    color: #096dd9;
  }

  .border-button {
    height: 25px;
    font-size: small;

    margin: 5px;
  }

  .commentForm-new {
    textarea {
      max-height: 150px;
      overflow-y: auto !important;
    }
  }

  &__moreBtn {
    cursor: pointer;
  }
}

.commentForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  textarea {
    border: none;
    background: #ffffff;
    font-size: 16px;
    // padding: 5px 0px;
    padding: 0;
    // border-radius: 5px;
    min-height: 50px;
    max-height: 150px;
    width: 100% !important;
    overflow-y: auto !important;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
  button {
    float: right;
    margin: 10px;
  }
}
.w-100 {
  width: 100%;
}

.messageInternal-block {
  padding: 20px;
  margin-bottom: 10px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  line-break: anywhere;
  .commentListItem__user-img {
    position: relative;
    display: flex;
    align-items: center;
    .active {
      height: 10px;
      width: 10px;
      border-radius: 20px;
      background-color: #096dd9;
      margin-right: 10px;
    }
  }

  .compose-user-name {
    font-weight: normal;
  }
  .compose-user-time {
    margin-left: auto;
    color: #333;
  }
  .messageTicket-number {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      color: #333;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0 8px;
      box-shadow: 0px 0px 7px 0px #e2e2e2;
    }
  }
  .message-listingBlock {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.light-blue-bg {
    background-color: #ecf6ff;
  }
}

.commentListItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 26px;
  gap: 8px;

  &__user {
    width: 100%;
    display: flex;
    gap: 10px;

    &-img {
      //height: 32px;
      //width: 32px;
      //flex-grow: 0;
      //flex-shrink: 0;
      //flex-basis: 32px;
      //border-radius: 50%;
      // overflow: hidden;
      // border: 1px solid #ddd;

      img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        padding: 2px;
      }
    }

    &-info {
      width: 100%;
    }

    &-heading {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 8px 0;
    }

    &-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    &-time {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    &-comment {
      background: #ecf6ff;
      border-radius: 4px;
      padding: 12px;
      width: 100%;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &.otherUser {
    .commentListItem__user-comment {
      background: #f5f5f5;
    }
  }

  &.generated {
    .commentListItem__user-comment {
      background: #fff;
      padding: 12px 0;
    }
  }

  &__moreBtn {
    cursor: pointer;
  }
}

.stepperForm__inner {
  max-width: 750px;
  margin: 0 auto 56px;
  padding: 0px 10px;
  padding-top: 30px;
  &.p-30px {
    padding: 30px !important;
  }
  &.message-templateBclobk {
    margin: 0;
  }
}

.message-templateBclobk .fromGroup-chips {
  align-items: center;
}
.fromGroup-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.message-templateBclobk .fromGroup-chips label {
  color: #000;
  margin-bottom: 0;
}

.message-templateBclobk .fromGroup-chips .MuiPaper-rounded {
  margin-bottom: 0;
  margin-top: 0;
}

.fromGroup-chips .MuiPaper-root {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  margin: 4px 0;
}

.fromGroup-chip-active {
  border: 1px solid #096dd9 !important;
  color: #096dd9 !important;
}

.fromGroup input {
  padding: 8px 12px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
}

.commentList-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  button {
    background: #ffffff;
    border: 1px solid #096dd9;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #096dd9;
    padding: 4px 15px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: #096dd9;
      color: #fff;
    }
  }
}
@media only screen and (max-width: 425px) {
  .top-btn-list {
    .sms-btn {
      font-size: 15px;
      padding: 20px 20px;
    }
  }
}
