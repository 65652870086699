.menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &__link {
      color: #000000;
      font-weight: 400;
      font-size: 18px;
      margin: 0px 22px;
      line-height: 32px;
      position: relative;
      font-family: 'Roboto', sans-serif;
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        border-radius: 10px;
        background-color: #096DD9;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        opacity: 0;
      }
    }
    &--active {
      .menuItem__link {
        color: #096dd9;
        font-weight: 700;
        &::after {
          opacity: 100%;
        }
      }
    }
  }
  
  @media only screen and (max-width: 1400px) { 
    .menuItem {
      &__link {
        font-size: 16px;
        margin: 0px 12px;
      }
    }
  }
  
  @media only screen and (max-width: 1200px) { 
    .menuItem {
      &__link {
        font-size: 14px;
        line-height: 26px;
        margin: 0px 10px;
      }
    }
  }
  
  @media only screen and (max-width: 991px) {  
    .menuItem {
      margin: 20px;
    }
  }