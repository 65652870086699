.listingTable {
  padding: 0 10px;
  .listingTable__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 22px;
  }

  .listingTable__title {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
  }

  .tableResponsive {
    box-shadow: none;
  }

  table {
    border-spacing: 0 8px;

    tr {
      min-height: 48px;
      margin-bottom: 8px;
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
      font-family: "Roboto", sans-serif;
      white-space: pre;
      vertical-align: middle !important;
    }

    .MuiTableCell-head {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre;
    }

    tbody {
      tr {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px !important;

        tr:nth-child(odd) {
          background: #ecf6ff;
        }
      }

      td {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
        vertical-align: middle !important;
      }
    }
  }
}

.TableBlock {
  table {
    tr {
      min-height: 48px;
      margin-bottom: 8px;
    }
  }
}

.listingTable__header.d-block {
  display: block !important;
}

@media only screen and (max-width: 991px) {
  .listingTable__header {
    display: block;
  }
  .listingTable__title {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 560px) {
  .listingTable__header {
    flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
  .listingTable__title {
    margin-bottom: 10px;
  }
}

.select-filters-buttons {
  // display: flex;
  // // flex-wrap: wrap;
  // align-items: center;
  // gap: 15px;

  .selected-filtersButtons {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: column;

    // button {
    //   font-family: "Roboto", sans-serif;
    //   background: #ecf6ff !important;
    //   border: 1px solid #096dd9 !important;
    //   border-radius: 2px;
    //   padding: 7px 15px;
    //   font-weight: 400;
    //   font-size: 16px;
    //   line-height: 18px;
    //   color: #096dd9 !important;
    //   margin: 5px 0;
    //   margin-right: 8px;
    //   min-height: 32px;
    // }
  }

  .filterGroup-btn {
    flex-grow: 0;
    flex-shrink: 0;
    // flex-basis: 160px;
    flex-basis: auto;
  }
}

.listingTable {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 22px;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
  }

  .tableResponsive {
    box-shadow: none;
    // padding:0 15px;
  }

  table {
    border-spacing: 0 8px;

    tr {
      min-height: 48px;
      margin-bottom: 8px;
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
      font-family: "Roboto", sans-serif;
      white-space: pre;
      vertical-align: middle !important;
    }

    .MuiTableCell-head {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre;
    }

    tbody {
      tr {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px !important;

        tr:nth-child(odd) {
          background: #ecf6ff;
        }
      }

      td {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
        vertical-align: middle !important;
      }
    }
  }
}

.userList-name span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0;
}

.userList-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userSmallCircle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.scheduleFilterCard {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 0px 0px 4px 4px;
  padding: 16px 12px;
  max-width: 350px;
  margin-left: auto;

  & > div:last-child {
    border: none;
  }
}

.form-control {
  width: 100% !important;
  svg {
    // padding-left: 20px;
  }
}

.calendarModule {
  .Calendar {
    width: 100%;
    padding: 0;
    min-height: auto;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    border-radius: 2px;
    max-width: 330px;
    margin: 0 auto;

    &__header {
      background: #096dd9;
      box-shadow: inset 0px -1px 0px #f0f0f0;
      border-radius: 2px 2px 0px 0px;
      height: 40px;
      padding: 12px 15px;

      button {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        padding: 0 2px;
      }
    }

    &__monthArrow {
      height: 12px;
      width: 12px;
      border-left: 3px solid #fff;
      border-bottom: 3px solid #fff;
      border-radius: 0;
      transform: rotate(135deg);
      background: none;
    }

    &__weekDays {
      margin-top: 20px;
      padding: 0 12px;
    }

    &__weekDay {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
    }

    &__section {
      padding: 0 12px;
    }

    &__day {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);

      &.-weekend {
        color: rgba(0, 0, 0, 0.85) !important;
      }

      &.-today {
        height: 24px;
        background: #096dd9;
        border-radius: 2px;
        color: #fff !important;
      }

      &::after {
        display: none !important;
      }
    }
  }
}

.selectFilter {
  padding: 22px 0;
  border-bottom: 1px solid #d9d9d9;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .link {
      text-decoration: none !important;
    }
  }

  &__heading {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    color: #000000;
  }

  &__body {
    .MuiFormControlLabel-root {
      display: block;
    }
  }
}

.notFound-Block {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #666666;
}

.listingTable {
  &--gray {
    .tableResponsive {
      padding: 3px;
    }
    .listingTable__header {
      align-items: center;
    }
    table {
      border-spacing: 0 15px;
      tbody {
        tr {
          background: #f5f5f5 !important;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
          td {
            &:last-child {
              color: #096dd9 !important;
              svg {
                height: 30px;
                width: 30px;
              }
            }
            .tableDetailsBox {
              &__time {
                color: #777 !important;
              }
              button {
                padding: 0;
                text-transform: capitalize;
                text-decoration: underline;
              }
            }
            .scheduleStatus {
              padding: 0px 15px;
              border-radius: 35px;
              height: 32px;
              min-width: 110px;
              display: inline-block;
              box-sizing: border-box;
              text-align: center;
              color: #000;
              border: 1px solid #096dd9;
              background: #ecf6ff;
            }
          }
        }
      }
    }
  }
}

.detailsCard {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 100%;

  &__header {
    background: #096dd9;
    border-radius: 4px 4px 0px 0px;
    padding: 15px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    margin: 0;
  }

  &__headBtn {
    // color: white !important;
    // padding: 0;
    // border-bottom: 1px solid #fff;
    // border-radius: 0;
    // background: transparent !important;
    // padding-bottom: 3px;
    padding: 0;
    border-radius: 0;
    background: transparent !important;
    padding-bottom: 3px;
    svg {
      height: 20px;
      width: 20px;
      color: white;
    }
  }

  &__body {
    padding: 20px;
    height: calc(100% - 50px);

    & > div:last-child {
      margin-bottom: 0;

      .detailsCard-part {
        margin-bottom: 0;
      }
    }
  }

  &-lastBtn {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #096dd9 !important;
    background: transparent !important;
    border: none;
    padding: 0;
    margin: 15px 0 0;

    svg {
      margin-left: 0;
    }
  }

  &__stepper {
    padding: 20px 0 40px;
  }
}

.availabilityStatus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  background: #ecf6ff;
  border-radius: 4px;
  padding: 10px;
  max-width: 200px;
  margin: 10px auto;

  span {
    padding: 5px 15px;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    padding-left: 32px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
      content: "";
      height: 20px;
      width: 20px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      display: block;
      position: absolute;
      left: 0;
    }

    &.availableBox {
      &::before {
        background: #6bb283;
      }
    }

    &.unAvailableBox {
      &::before {
        background: #ffffff;
      }
    }

    &.fullyBookBox {
      &::before {
        background: #000;
      }
    }

    &.partiallyBookBox {
      &::before {
        background: #096dd9;
      }
    }
  }
}

.availabilityStatusHorizontal {
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 335px;
  margin: 15px auto 0;

  span {
    padding: 5px 22px;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
      content: "";
      height: 16px;
      width: 16px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18);
      border-radius: 2px;
      display: block;
      position: absolute;
      left: 0;
    }

    &.availableBox {
      &::before {
        background: #6bb283;
      }
    }

    &.unAvailableBox {
      &::before {
        background: #ffffff;
      }
    }

    &.fullyBookBox {
      &::before {
        background: #000;
      }
    }

    &.partiallyBookBox {
      &::before {
        background: #096dd9;
      }
    }
  }
}

.react-calendar-heatmap-month-label,
.react-calendar-heatmap-weekday-label {
  font-size: 8px !important;
  fill: #000 !important;
}

.react-calendar-heatmap .color-filled {
  fill: #6bb283 !important;
}

.react-calendar-heatmap .color-empty {
  fill: #eeeeee !important;
}

.react-calendar-heatmap .color-pending {
  fill: #096dd9 !important;
  stroke: red;
  stroke-width: 1px;
  stroke-dasharray: 1, 1.5;
  stroke-linecap: round;
}

.react-calendar-heatmap .color-square-0 {
  fill: #6bb283 !important;
}

.react-calendar-heatmap .color-square-1 {
  fill: #096dd9 !important;
}

.react-calendar-heatmap .color-square-2 {
  fill: #000 !important;
}


