.session {
  padding: 30px;
  max-height: calc(100vh - 145px);
  overflow: auto;
  .sessionTitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .session-card {
    padding: 10px;
    margin: 10px;
    min-height: 230px;
    max-width: 298px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    .sessionDate {
      display: flex;
      gap: 15px;
      align-items: center;
      h6 {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .sessionTime {
      // padding: 10px;
      font-weight: 500;
      margin-left: 37px;
      font-size: 16px;
    }
    .clientDetails {
      display: flex;
      gap: 10px;
      margin: 10px 0px 0px 0px;
      align-items: center;
    }
    .session-Type {
      font-size: 16px;
      font-weight: 500px;
      color: #8c8c8c;
      margin-left: 40px;
    }
    .startSession-btn {
      justify-content: center;
    }
    button {
      padding: 7px 16px;
    }
  }

  .sessionlist {
    margin-top: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    text-transform: none;
  }
  .noSessions {
    height: 270px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .tableResponsive {
    .MuiTableCell-head {
      font-size: 18px;
    }
    .noSession-block {
      min-height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      background: #ecf6ff;
      border-radius: 4px;
    }
  }
}

.sessionDetails {
  padding: 30px;
  max-height: calc(100vh - 145px);
  overflow: auto;
  .details-header {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .session-Title {
    font-size: 24px;
    font-weight: 700;
  }
  .details-tab {
    .tabs {
      list-style: none;
      display: flex;
      gap: 20px;
      padding-left: 2px;
      li {
        padding: 5px;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
      }
      .active-li {
        color: #1976d2;
        border-bottom: 2px solid #1976d2;
      }
    }
  }
  .closeSession {
    float: right;
  }

  .session-summary {
    padding-top: 20px;
    .card-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 10px;
    }
    .carDescription {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #8c8c8c;
    }
    .stepperStage {
      margin: 0px 0px 10px 0px;
      ul {
        padding-left: 60px;
      }
      .linkForm{
        color: #096DD9;
        cursor: pointer;
      }
    }
    .summary-card {
      position: relative;
      padding: 15px;
      p {
        margin-bottom: 4px;
      }
      .css-46bh2p-MuiCardContent-root {
        padding: 0px;
      }
      .card-header {
        background: #096dd9;
        color: #fff;
        padding: 10px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
      .sheduleDetails {
        padding: 10px 0px;
        display: flex;
        gap: 10px;
      }
      .sub-list {
        font-size: 16px;
        font-weight: 600;
      }
      .timeDetails {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
      }
      .time-dropDown {
        margin-left: 0;
      }
      .userDetails {
        margin: 12px 0px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    .signOut-procedure {
      display: grid;
      grid-template-columns: 3fr 1fr;
      margin-top: 22px;
      column-gap: 21px;
      @media only screen and (max-width: 1024px) {
        grid-template-columns: 2fr 1fr;
      }
    }
    .signIn-procedure {
      display: grid;
      grid-template-columns: 4fr 1fr;
      margin-top: 22px;
      column-gap: 12px;
      @media only screen and (max-width: 1024px) {
        grid-template-columns: 3fr 1fr;
      }
    }
    
  }
  .largeModel {
    .closeSession {
      font-size: 16px;
      font-weight: 500;
    }
  }
  button {
    text-transform: none;
    gap: 5px;
  }
}

.cardSlider {
  width: calc(100% - 84px);
  margin: 0 42px;
  position: relative;
  .carousel__slide {
    padding: 0 !important;
    height: 270px;
  }
  .slider {
    height: 270px;
  }
  .sliderNavBtn {
    position: absolute;
    padding: 0;
    height: 30px;
    width: 30px;
    border: none;
    background: transparent;
    top: 50%;
    left: -38px;
    transform: translateY(-45%);
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    // background: #096dd9;
    border-radius: 50%;

    &.carousel__next-button {
      right: -38px;
      left: auto;
    }

    svg {
      height: 14px;
      width: 14px;
    }

    &:disabled {
      svg path {
        stroke: #ccc;
      }
    }
  }
}

.sessionForms {
  .intakeForm_inner {
    margin: 0 7px;
  }
  .cardSlider {
    .slider {
      height: 220px !important;
    }
    .carousel__slide {
      height: 220px;
      // margin: 4px;
    }
  }
  .session-Request {
    margin-top: 20px;
    .formRequest-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      margin: 20px 0px;
    }
    .tableResponsive {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      th {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.sessionUpdates {
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 2px;
  }
  .comment-box {
    max-height: calc(100vh - 315px);
    overflow: auto;
  }
  .comment {
    margin: 20px 0px;

    .comment-details {
      display: flex;
      align-items: center;
      gap: 55px;
      margin-bottom: 5px;
    }
    .comment-dtm {
      font-size: 14px;
      font-weight: 300;
      color: rgba(0, 0, 0, 0.85);
    }
    .userName {
      font-size: 16px;
      font-weight: 700;
    }
    .message {
      margin-left: 40px;
    }
  }
  .comment-btn {
    position: absolute;
    right: 10px;
    top: 9px;
  }
}
.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.m-25 {
  margin: 25px;
}
.closeTime {
  width: 110px;
  margin-top: 10px;
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 6.5px 14px;
  }
}
.p-25 {
  padding: 25px;
}
.back-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1976d2;
  cursor: pointer;
}
.patientSignOut {
  height: calc(100vh - 140px);
  overflow: auto;
  padding: 25px;
  .signOutHeader {
    display: flex;
    width: 56%;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      display: block;
      width: 100%;
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
    }
  }
  .sd-title.sd-container-modern__title {
    display: none;
  }
  .signOutComplete {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dateMessage {
  border-radius: 25px;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  width: fit-content;
  border: 3px solid #6bb283;
  background-color: white;
}
