//control schedule popup window
.schedule-detail-model {
    .MuiDialog-paper {
      max-width: 710px;
      padding: 0 40px;
      .largeModel__head {
        // background: #ffffff;
        border-bottom: 1px solid #dee2e6;
        padding: 12px 14px 8px 12px;
        .largeModel__title {
          color: #fff; // #212529;
          font-size: 18px;
          font-weight: 500;
        }
        .largeModel__closeBtn {
          background-color: transparent !important;
          color: #fff; //#212529 !important;
        }
      }
      .largeModel__body {
        .bookingModel {
          padding: 20px;
          .bookingModel__form {
            margin-top: 15px;
            .userList-name {
              span {
                font-size: 15px;
                font-weight: bold;
              }
              .userSmallCircle {
                height: 35px;
                width: 35px;
              }
            }
            .MuiGrid-item {
              padding-top: 18px;
            }
            .fromLabel {
              color: #000;
              font-size: 16px;
              font-weight: bold;
            }
            .fromGroup-chips {
              .MuiPaper-root {
                height: auto;
                font-size: 14px;
                line-height: normal;
              }
            }
            form {
              .MuiGrid-item {
                input {
                  height: auto;
                  padding: 6.5px 12px;
                }
                button {
                  margin-right: 10px;
                  line-height: normal;
                  padding: 10px 14px;
                }
              }
              .MuiGrid-item.MuiGrid-grid-xs-12 {
                &:first-child {
                  padding-top: 0 !important;
                  .fromGroup {
                    margin-bottom: 20px;
                  }
                }
              }
            }
          }
        }
      }
      .largeModel__footer {
        .button {
          padding: 7px 15px;
          height: auto;
          font-size: 15px;
          line-height: normal;
          font-weight: normal;
        }
      }
    }
  }
  

  .control-section {
    .e-schedule {
      .template-wrap {
        width: 100%;
      }
    
      //view: day, week
      .e-vertical-view {
        .e-work-cells {
          background: rgb(238, 238, 238) !important;
          &.e-work-hours {
            background: #6bb283 !important;
          }
        }
      }
  
      //view: timeline day, timeline week
      .e-timeline-view {
        .e-work-cells {
          background: rgb(238, 238, 238) !important;
          &.e-work-hours {
            background: #6bb283 !important;
          }
        }
      }
  
      //view: month
      .e-month-view {
        .e-work-cells {
          // background: rgb(238, 238, 238) !important;
          &.e-work-hours {
            background: #6bb283 !important;
          }
   
        }
      }
  
      // //view: timeline month
      // .e-timeline-month-view {
      //   .e-work-cells {
      //     // background: rgb(238, 238, 238) !important;
      //     &.e-work-hours {
      //       background: #fff; // #096dd9 !important;
      //     }
      //   }
      // }
    }
  }
  
    //make timeline view (day, week, month) looks nice!
    // .schedule-control-section {
    //   .e-schedule {
    //     .e-timeline-view .e-day-view .e-agenda-view {
    //       .e-resource-column-wrap {
    //         table td {
    //           padding: 0;
    //           div {
    //             width: 100%;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // .e-block-appointment {
    //   background: #d0d0d5;
    // }
    // .e-timeline-view {
    //   .e-resource-cells {
    //     height: 65px;
    //   }
    // }
    // }
  // }
  
  .justify-content-between {
    justify-content: space-between !important;
  }
  
  .fromGroup-repeatDay {
    display: flex;
    justify-content: space-between;
    div {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background-color: #eee;
      text-align: center;
      line-height: 30px;
    }
    .fromGroup-dayName {
      background-color: #096dd9;
      color: #fff;
    }
  }
  
  .check-AuthBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
    input {
      margin-right: 16px;
    }
  }
  
  .datepickerBlock {
    width: 140px;
    label,
    legend {
      display: none;
    }
    .MuiIconButton-edgeEnd {
      background-color: transparent !important;
      svg {
        color: #096dd9;
        font-size: 20px;
      }
    }
    input {
      border: 0;
      font-size: 14px;
      margin: 0;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: 0 !important;
    }
  
    .MuiOutlinedInput-notchedOutline {
      top: 0;
    }
  }
  .deleteSchedule.schedule-detail-model .MuiDialog-paper {
    padding: 0;
  }
  .checkBoxEdit {
    margin-bottom: 18px;
    label {
      margin-left: 0 !important;
      span {
        color: #000;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  
  .flexNowrap {
    flex-wrap: nowrap !important;
    @media only screen and (max-width: 991px) {
      flex-wrap: wrap !important;
    }
  }
  //view: timeline day, timeline week
  .control-section {
    .e-schedule {
      .template-wrap {
        width: 100%;
      }
    
      //view: day, week
      .e-vertical-view {
        .e-work-cells {
          background: rgb(238, 238, 238) !important;
          &.e-work-hours {
            background: #6bb283 !important;
          }
        }
      }
  
      //view: timeline day, timeline week
      .e-timeline-view {
        .e-work-cells {
          background: rgb(238, 238, 238) !important;
          &.e-work-hours {
            background: #6bb283 !important;
          }
        }
      }
  
      //view: month
      .e-month-view {
        .e-work-cells {
          // background: rgb(238, 238, 238) !important;
          &.e-work-hours {
            background: #6bb283 !important;
          }
   
        }
      }
  
      // //view: timeline month
      // .e-timeline-month-view {
      //   .e-work-cells {
      //     // background: rgb(238, 238, 238) !important;
      //     &.e-work-hours {
      //       background: #fff; // #096dd9 !important;
      //     }
      //   }
      // }
    }
  }

  .e-schedule {
    .e-timeline-view {
      .e-resource-column-wrap {
        table td {
          padding: 0;
          div {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .meetingPersonBox {
    border-radius: 0;
    margin: 0;
    padding: 5px 10px;
    gap: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

.e-quick-popup-wrapper {
  .e-popup-footer {
    .e-event-details {
      display: none !important;
    }
    .e-event-delete {
      display: none !important;
    }
  }
  .e-cell-popup {
    .e-popup-content {
      .e-popup-table {
        .e-schedule-form > span {
          display: none;
        }
      }
    }
  }
}

.e-btn.e-flat.e-primary {
  display: none !important;
}

