.pageTemplate {
  padding: 32px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 25px;
  }

  &__title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: rgba(0, 0, 0, 0.85);
    text-transform: capitalize;
  }
}

.uploadBlock {
  margin-bottom: 50px;

  &__title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  .uploadCard {
    height: 176px;
    margin-top: 25px;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fileListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &__heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__name {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    // color: #fa8c16;
  }

  &__status {
    background: #fafafa !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    padding: 3px 16px !important;
    min-height: 0;
    height: auto;
    text-transform: capitalize !important;

    &--done {
      background: #002766 !important;
      border: 1px solid #002766 !important;
      color: #fff !important;
    }
  }
}

.uploadCoustomBtn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
  border-radius: 2px;
  padding: 5px 15px;
  gap: 10px;
  margin-left: 20px;
  align-items: center;
  cursor: pointer;

  svg path {
    fill: #096dd9;
  }

  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .btn {
    width: 104px;
    height: 104px;
    background: #096dd9;
    border: 1px dashed #ffffff;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      height: 16px;
      width: 16px;
      margin-bottom: 5px;
    }
  }

  & > .MuiBox-root {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    opacity: 0%;
    button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}

.fileCardsModule {
  display: flex;
  gap: 80px;
  margin-top: 20px;
}

.formCard {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  background: #ffffff;
  border: 0.5px solid #8c8c8c;
  width: 285px;
  cursor: pointer;
  &__img {
    height: 260px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    background: #f0f0f0;
    border: none;
    padding: 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #434343;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  &__moreBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.documentCard {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  background: #ffffff;
  border: 0.5px solid #c3bebe;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  &__img {
    background: #f0f0f0;
    height: 155px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 0;
    overflow: hidden;
    box-sizing: border-box;
    &-card {
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
      background: #ffffff;
      border-radius: 5px;
      text-align: left;
      padding: 12px;
      background: #ffffff;
      border-radius: 5px 5px 0 0;
      text-align: left;
      padding: 25px;
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }
  &__btn {
    background: #fff;
    border: none;
    padding: 12px 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #434343;
    justify-content: space-between;
    border-top: 1px solid #c3bebe;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
    span {
      padding: 8px 20px;
      border-radius: 5px;
      background-color: #096dd9;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.TableBlock.filesTable {
  margin-bottom: 50px;

  .MuiTableContainer-root {
    padding: 5px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 20px;
    .fileListItem__name {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 32px !important;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  thead tr {
    background: #f5f5f5;
    border: 0.5px solid #8c8c8c;
    box-shadow: 0px 0px 2px #8c8c8c;

    th {
      border-bottom: none;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #096dd9;
      padding: 14px;
      white-space: nowrap;

      &:last-child {
        width: 300px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d9d9d9;
      background-color: #fff;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      &:nth-child(odd) {
        background: #ecf6ff;
      }

      td {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.85);
        white-space: nowrap;

        &:last-child {
          width: 300px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .pageTemplate {
    padding: 22px;
  }

  .dashboard {
    padding: 54px 22px;
  }

  .TableBlock {
    width: 50%;
    thead tr th {
      font-size: 18px;
      line-height: 25px;
      padding: 10px 14px;
    }

    tbody tr td {
      font-size: 16px;
      line-height: 28px;
      padding: 14px;
    }
  }

  .fileListItem {
    &__name {
      font-size: 16px !important;
      line-height: 28px !important;
    }

    &__heading {
      svg {
        height: 28px;
        width: 28px;
      }
    }
  }
}

.largeModel {
  padding: 20px;

  &__closeBtn {
    padding: 0;
    min-width: 0;
    height: 20px;
    width: 20px;
  }

  .MuiDialog-paper {
    max-width: 900px;
    width: 100%;
    margin: 0 auto !important;
    border-radius: 8px;
  }

  &__head {
    background: #096dd9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
    position: sticky;
    top: 0;
    bottom: 10px;
    z-index: 11;
  }
  &__body {
    position: relative;
    z-index: 0;
  }
  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin: 0;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    justify-content: flex-end;

    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
    }

    .button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
      &:hover {
        background: #fff !important;
        color: #096dd9 !important;
      }
    }
  }

  &__stepperHead {
    display: flex;
    justify-content: center;
    position: sticky;
    //top: 34px;
    background: #fff;
    z-index: 12;
    //padding: 0px 0;
    //margin-bottom: 10px;

    .MuiStepper-horizontal {
      justify-content: center;
    }

    .MuiStep-root {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 125px;
    }

    .MuiStepLabel-iconContainer {
      svg {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        border: 2px solid rgba(0, 0, 0, 0.25);

        text {
          fill: rgba(0, 0, 0, 0.25) !important;
        }
      }

      &.Mui-active {
        svg {
          border: none;
          color: #096dd9;

          text {
            fill: #fff !important;
          }
        }
      }

      &.Mui-completed {
        svg {
          border: none;
          color: #096dd9;
        }
      }
    }

    .MuiStepLabel-label {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: rgba(0, 0, 0, 0.25);

      &.Mui-active {
        color: #096dd9;
      }
    }

    .MuiStepConnector-root {
      top: 22px;
      left: calc(-50% + 22px);
      right: calc(50% + 22px);
      z-index: 0;

      .MuiStepConnector-line {
        border-color: #d9d9d9;
        border-top-width: 3px;
      }
    }

    .MuiStepLabel-root {
      position: relative;
      z-index: 1;
    }
  }

  &--edit {
    .stepperForm__title {
      margin: 40px auto 24px;
    }

    .InsuranceHeader {
      margin-top: 40px;
    }
  }
}

.stepperForm__footer {
  position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
      text-transform: none;
    }
    .button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
      text-transform: none;
    }
}

.selectedIn-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
    line-break: anywhere;
    margin-left: 24px;
  }

  span {
    cursor: pointer;

    svg {
      color: #096dd9;
      height: 20px;
      width: 20px;
    }
  }
}

.fileUploadBlock {
  position: relative;
  text-align: center;
  background: #ecf6ff;
  border: 1px solid #096dd9;
  box-sizing: border-box;
  & > .MuiBox-root {
    position: absolute;
    border: none;
    border-radius: 2px;
    margin: 24px;
    padding: 0;
    top: 13%;
    left: 25%;
    & > .MuiBox-root {
      display: none !important;
    }
  }

  .MuiFormControl-root {
    width: 88%;
    padding: 30px;
    .css-17ru0vy-MuiInputBase-root-MuiOutlinedInput-root {
      height: 50px;
    }
  }

  .MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }

  .MuiButtonBase-root.MuiButton-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    opacity: 0;
  }
  .uploadIcon {
    font-size: 50px;
  }
}

.largeModel__head .largeModel__closeBtn {
  text-transform: unset;
    background-color: rgb(9, 109, 217);
    color: white;
}

.tableIconBtn {
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  margin-right: 20px;
  color: #1976d2;
}

.smallModel {
  .MuiDialog-paper {
    max-width: 500px;
    width: 100%;
  }

  .MuiDialogContent-root {
    padding: 30px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    max-width: 600px;
    text-align: center;
  }

  .MuiDialogActions-root {
    position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
    }

    .button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
    }
  }
}