.formReview {
  // position: relative;
  @media (max-width: 768px) {
    max-height: calc(100vh - 145px);
    overflow: auto;
  }
}
.back-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1976d2;
  cursor: pointer;
}
.formReviewHeader {
  padding: 20px;
  border-bottom: 1px solid #d9d9d9;
  margin: 0 !important;
  width: 100% !important;
  @media (max-width: 1299px) {
    padding: 15px 30px;
  }
  // @media (max-width: 1200px) {
  //   display: grid !important;
  //   grid-template-columns: 1.5fr 2fr 1fr;
  // }
  // @media (max-width: 768px) {
  //   gap: 15px;
  //   display: flex !important;
  //   flex-wrap: wrap !important;
  // }
  @media (max-width: 575px) {
    display: grid !important;
    grid-template-columns: 1fr;
  }
  .firstSection {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 1299px) {
      gap: 10px;
    }
    // @media (max-width: 768px) {
    //   flex-direction: column;
    //   align-items: flex-start;
    //   width: 38%;
    // }
    .formTitle-session {
      line-height: 32px;
      font-size: 24px;
      font-weight: 700;
      @media (max-width: 1299px) {
        font-size: 18px;
        line-height: normal;
      }
    }
  }
  .midSection {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
    @media (max-width: 1024px) {
      gap: 15px;
      justify-content: start;
    }
    @media (max-width: 1250px) {
      padding: 0px;
    }
    // @media (max-width: 768px) {
    //   width: 58%;
    // }
    .entityName {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      @media (max-width: 1024px) {
        font-size: 18px;
        line-height: normal;
      }
    }
    .ClientName {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      @media (max-width: 1299px) {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
  .lastSection {
    display: flex;
    justify-content: flex-end;
    // @media (max-width: 768px) {
    //   width: 100%;
    // }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-align: right;
    }
  }
}
.formReviewDetails {
  padding: 30px;
  @media (max-width: 1024px) {
    padding: 20px;
  }
  .formName {
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    // margin-bottom: 13px;
    @media (max-width: 1024px) {
      margin-bottom: 0px;
    }
  }
  .question-header1 {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px 10px 10px;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  .question-header2 {
    // display: flex;
    // justify-content: space-between;
    p {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
    }
    // margin: 0px 10px 10px 10px;
  }
  .questionLists {
    margin-top: 5px;
    max-height: calc(100vh - 323px);
    overflow: auto;
    @media (max-width: 1024px) {
      max-height: calc(100vh - 290px);
    }
    .questionBlock {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #d9d9d9;
      margin-bottom: 10px;
    }
  }
  .formDetails {
    .reviewQuestionset {
      padding: 0px 40px;
      @media (max-width: 1250px) {
        padding: 0px;
      }
    }
    // .questionTitle {
    //   font-size: 20px;
    //   font-weight: 600;
    //   line-height: 32px;
    //   text-align: left;
    //   margin-bottom: 5px;
    //   @media (max-width: 1199px) {
    //     font-size: 18px;
    //     line-height: normal;
    //   }
    // }
    // .sd-element__title {
    //   .sv-string-viewer {
    //     font-size: 22px;
    //     font-weight: 600;
    //     line-height: 32px;
    //     text-align: left;
    //     margin-bottom: 5px;
    //     @media (max-width: 1199px) {
    //       font-size: 18px;
    //       line-height: normal;
    //     }
    // }
    // }

    .optionsList {
      max-height: calc(100vh - 313px);
      @media (max-width: 1024px) {
        max-height: calc(100vh - 335px);
      }
      overflow: auto;
      .optionsCard {
        max-width: max-content;
        padding: 5px 10px;
        min-height: 44px;
        gap: 10px;
        border-radius: 4px;
        border: 2px solid #096dd98c;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
        }
        @media (max-width: 1024px) {
          min-height: 36px;
        }
      }
    }
    .active {
      border: 2px solid #096dd9;
      background: #ecf6ff;
    }
  }

  .reviewComments {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    margin-bottom: 20px;
    height: calc(100vh - 406px);
    @media (max-width: 1024px) {
      height: calc(100vh - 415px);
    }
    overflow: auto;
    .comment {
      margin-bottom: 16px;
      display: grid;
      grid-template-columns: 32px auto;
      gap: 12px;
      .userInfo {
        &__head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;
          margin-bottom: 10px;
        }
        .ClientName {
          font-size: 16px;
          font-weight: 700;
          line-height: 18px;
        }
        .commentTime {
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
        }
        .comment-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}
.customCheckBox {
  border: 1px solid #d9d9d9;
  padding: 6px 12px;
  margin-bottom: 8px;
  position: relative;
  border-radius: 2px;
  cursor: pointer;
  label {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    padding-right: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &:before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
    &:before {
      width: 26px;
      height: 26px;
      border: 2.5px solid #096dd98c;
      background-color: transparent;
      border-radius: 100%;
      right: 15px;
    }
    &::after {
      width: 17px;
      height: 14px;
      background-image: url(../../assets/images/images-svg/righttik.svg);
      right: 21px;
      background-repeat: no-repeat;
      background-size: contain;
      display: none;
    }
  }
  input {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 0;
    right: 15px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ label {
    &::before {
      background-color: #e6f7ff;
      border: 2.5px solid #02c34f;
    }
    &:after {
      display: block;
    }
  }
}
.signature-invitation {
  border: 1px solid #d9d9d9;
  padding: 6px 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    text-transform: none;
    padding: 0px 7px;
    // margin: 5px;
    border: 0px;
    padding: 5px;
    border-radius: 3px;
  }
  .invite {
    background-color: #1976d2;
    color: #fff;
  }
  .signed {
    background-color: #2AC960;
  }
  .requested {
    background-color: #ECF6FF;
  }
}
.CommentEntryBox {
  position: relative;
  .MuiFormControl-root {
    width: 100%;
    input {
      padding: 16px;
      padding-right: 110px;
      font-size: 16px;
      line-height: 18px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
    fieldset {
      border: none;
    }
  }
  button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.bg {
  background: #ecf6ff;
}
.review-survey {
  .sd-title.sd-container-modern__title {
    padding: 0px;
  }
  .sd-page {
    padding: 0px;
  }
  .sv-components-column {
    overflow-x: hidden;
    max-height: calc(100vh - 290px);
    padding-right: 10px;
    @media (max-width: 1024px) {
      padding-right: 15px;
    }
  }
  .sd-body.sd-body--static {
    padding: 0px;
    margin: 0px 2px;
  }
}

// .inviteButton_container {
//   button {
//     display: block;
//     margin-bottom: 10px;
//     min-width: 250px;
//     text-align: left;
//     text-transform: none;
//   }
// }

.smallModel {
  &__head {
    background: #096dd9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    button {
      color: #fff;
    }
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #fff;
    margin: 0;
  }
  &__closeBtn {
    padding: 0;
    color: #fff;
    min-width: auto;
  }

  &__footer {
    padding: 0px 20px 20px 20px;
    margin-top: 40px;
    position: absolute;
    bottom: 0;
    right: 0;

    .border-button {
      border-radius: 4px;
    }

    .button {
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      border-radius: 4px;
      font-weight: 700;
      padding: 10px 15px;
      &:hover {
        background: #fff !important;
        color: #096dd9 !important;
      }
    }
  }
  .fromLabel {
    font-weight: 500;
  }

  .fromGroup {
    padding: 20px;
  }
}
