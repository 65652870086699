.taskHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .drawer-handler {
    display: flex;
  }
  .taskFilter{
    display: flex;
    gap:15px;
    margin-bottom: 10px;
  }
  .taskFormController {
    width: 100%;
    display: flex;
    gap: 14px;
    label {
      margin: -12px -5px;
    }
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
  }
}

.taskBtn {
  background: transparent !important;
  text-transform: unset !important;
  color: #096dd9 !important;
  border: 1px solid #096dd9;
  border-radius: 4px;
  padding: 4px 15px;
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
}

.taskDetails_main {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  margin: 0px 0px 3px;
}

.taskDetails_sub {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.5);
}

.task_priority_Low {
  width: fit-content;
  padding: 1px 6px;
  border: 1px solid #9ea1d4;
  border-radius: 5px;
  line-height: initial;
  font-size: 16px;
  // font-weight: 600;
}

.task_priority_Medium {
  width: fit-content;
  padding: 1px 6px;
  border: 1px solid #ffc701;
  border-radius: 5px;
  line-height: initial;
  font-size: 16px;
  color: #000;
}

.task_priority_High {
  width: fit-content;
  padding: 1px 6px;
  border: 1px solid #fd8a8a;
  border-radius: 5px;
  line-height: initial;
  font-size: 16px;
  // font-weight: 600;
  color: #000;
}

.profile-picture {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  cursor: pointer;

  .image-outer {
    width: 32px;
    height: 32px;
    border-radius: 30px;
    // background-color: #dde7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .userSmallCircle {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.calender-outer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  // background-color: #f6fbff;
  padding: 4px 10px;
  width: 135px;
  input {
    box-sizing: border-box;
    outline: 0;
    padding: 0.75rem;
    // position: relative;
    width: 100%;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    opacity: 0;
  }
}

.task-status-InProgress {
  color: #000 !important;
  background-color: #f7d9c4 !important;
  border-radius: 5px !important;
  padding: 3px 10px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400 !important;
  width: fit-content;
}
.task-status-Done {
  color: #000 !important;
  background-color: #c9e4de !important;
  border-radius: 5px !important;
  padding: 3px 10px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400 !important;
  width: fit-content;
}
.task-status-NotStarted {
  color: #000 !important;
  background-color: #dbcdf0 !important;
  border-radius: 5px !important;
  padding: 3px 10px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400 !important;
  width: fit-content;
}
.task-status-Cancelled {
  color: #000 !important;
  background-color: #d9d9d9 !important;
  border-radius: 5px !important;
  padding: 3px 10px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400 !important ;
  width: fit-content;
}

.task-status-Low {
  color: #000 !important;
  background-color: rgb(24 144 255) !important;
  border-radius: 2px !important;
  padding: 3px 10px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400 !important;
  width: fit-content;
}

.task-status-Medium {
  color: #000 !important;
  background-color: #ffc701 !important;
  border-radius: 2px !important;
  padding: 3px 10px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400 !important;
  width: fit-content !important;
}

.task-status-High {
  color: #000 !important;
  background-color: #f5222d !important;
  margin-bottom: 10px !important;
  border-radius: 2px !important;
  padding: 3px 10px !important;
  font-size: 14px !important ;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400 !important;
  width: fit-content;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.reassigned_lits_outer {
  background: #ecf6ff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  max-height: 120px;
  overflow: auto;
  & > div {
    padding: 10px 12px 10px 38px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.edit-task {
  // background-color: rgb(241, 241, 241) !important;
  background-color: white !important;
  color: #302f2f !important;
  // margin-bottom: 3px;
  border-radius: 3px;
}

.assignModal-Search {
  position: relative;
  display: flex;
  align-items: center;
  & > svg {
    position: absolute;
    left: 15px;
    z-index: 1;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root {
    &::after,
    &::before {
      display: none;
    }
  }
  input {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px !important;
    border: none !important;
    padding-left: 38px !important;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 42px;
    display: flex;
    align-items: flex-end;
  }
}

.board-modelSearch-data {
  background: #fff; //#ecf6ff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  max-height: 200px;
  overflow: auto;
  width: 100%;
}

.assigned-row {
  padding: 10px 15px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.flex-image {
  display: flex;
  gap: "10px" !important;
  align-items: center;
}

.listingTable {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 22px;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
  }

  .tableResponsive {
    // box-shadow: none;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1) !important;
  }

  table {
    border-spacing: 0 8px;

    tr {
      min-height: 48px;
      margin-bottom: 8px;
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
      font-family: "Roboto", sans-serif;
      white-space: pre;
      vertical-align: middle !important;
    }

    .MuiTableCell-head {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre;
    }

    tbody {
      tr {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px !important;

        tr:nth-child(odd) {
          background: #ecf6ff;
        }
      }

      td {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        vertical-align: middle !important;
        span {
          white-space: break-spaces;
        }
      }
    }
  }
}
.MuiDialog-paper {
  max-width: 750px !important;
  //   padding: 0 40px;
  .editTaskModal {
    min-width: 750px;
    width: 100%;
  }
}
