// .mapWrapper {
//   .innerTabs {
//     margin: 0 0 22px;
//   }
//   .splitter-layout {
//     left: 0;
//     & > .layout-splitter {
//       flex: 0 0 auto;
//       width: 4px;
//       height: 100%;
//       cursor: col-resize;
//       background-color: #ccc;
//       background: transparent;
//       border-left: 1px solid #ccc;
//       border-right: 1px solid #ccc;
//     }
//     .layout-pane-primary {
//       background-color: transparent;
//     }
//   }
// }
.task-sidePanel {
  display: flex;
  font-size: larger;
  font-weight: 500;
  gap: 9px;
  padding-left: 10px;
  // padding-top: 30px;
}

.panel-child {
  font-size: 16px;
  font-weight: 400;
  margin-left: 27px;
  padding: 5px;
  margin-top: 7px;
  margin-right: 7px;
}

.panel-child:hover {
  background-color: #adcff3;
  border-radius: 4px;
}
.activeBtn {
  background-color: #096dd94f;
  border-radius: 4px;
  // font-weight: 600;
  color: #096dd9;
}

.MuiButtonBase-root {
  line-height: 24px;
}

.MuiTypography-h5 {
  line-height: 32px;
}

.task-template {
  width: 100%;
  // height: calc(100vh - 64px);
  display: flex;
  .sidePanel-card {
    position: fixed;
    width: 250px;
    height: calc(100vh - 80px);
    overflow: auto;
    background-color: rgb(236, 246, 255);
    display: sticky;
    left: -250px;
    transition: 0.3s;
    &__closeBtn {
      display: none;
      @media (max-width: 991px) {
        display: block;
        margin: 10px 10px 0 auto;
        border: none;
        background: transparent;
      }
    }
    &.open {
      left: 0;
    }
    @media (max-width: 991px) {
      left: -280px;
      position: absolute;
      z-index: 5;
      width: 280px;
    }
    .open {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .task-content {
    transition: 0.3s;
    width: 100%;
    margin-left: auto;
  }
  .sidePanel-card.open ~ .task-content {
    width: calc(100% - 250px);
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}
